<template>
  <div id="offers">
    <h2 class="title">Nos partenaires vous font profiter d’avantages exclusifs</h2>
    <Offers-SectionsBenefits
      id="benefits"
      :assurance_pno="avantages.assurance_pno"
      :garantie_loyers_impayes="avantages.garantie_loyers_impayes"
      :expert_comptable="avantages.expert_comptable"
      variant="gestion"
      v-if="displayBenefits"
    />
  </div>
</template>

<script>
import { useAuthStore } from '../../../stores/auth'
import { usePartnerStore } from '../../../stores/partner'

export default {
  computed: {
    businessPartner() {
      return useAuthStore().user?.partner_name || useCookie('partner_name').value
    },
    avantages() {
      const partnerStore = usePartnerStore()
      return {
        assurance_pno: partnerStore.assurance_pno,
        garantie_loyers_impayes: partnerStore.garantie_loyers_impayes,
        expert_comptable: partnerStore.expert_comptable,
      }
    },
    displayBenefits() {
      return (
        !this.businessPartner ||
        this.avantages.assurance_pno ||
        this.avantages.garantie_loyers_impayes ||
        this.avantages.expert_comptable
      )
    },
  },
}
</script>

<style lang="less" scoped>
#offers {
  .app-padding();
  padding-top: 30px;

  .title {
    font-size: 24px;
    font-weight: var(--ds-weight-semi-bold);
    color: var(--ds-color-primary-100);
    text-align: center;
  }

  @media @bp-desktop {
    padding-top: 50px;
    padding-bottom: 50px;

    .title {
      font-size: 32px;
    }
  }
}
:deep(section.benefits-section) {
  height: auto !important;
}
:deep(section.section.faq) {
  margin-top: 50px;
  height: auto !important;
}
</style>
